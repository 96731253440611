/**
 * Created by Murphy.lee on 16/4/6.
 */
$(function() {
    $(".slideUp").html('<ul class="one_border hairlines"><a href="index.html"><li class="border_inner">首页</li></a> <a href="http://a.app.qq.com/o/simple.jsp?pkgname=com.davdian.seller"><li class="border_inner">下载app开店</li></a> <a href="http://bravetime.davdian.com"><li class="border_inner">进入店铺看看</li></a> <a href="http://bravetime.davdian.com/348.html"><li class="border_inner">如何加入大V店</li></a> <a href="http://s.davdian.com/index.php?m=admin&c=index&a=login"><li class="border_inner">登录 / 注册</li></a></ul>');

    //d顶部下拉菜单
    $(".menu_btn").click(function () {
        var slideup = $(".slideUp");
        var s_height = slideup.height();
        slideup.toggleClass("Ups");
        $(".menu_line_top").toggleClass("string1");
        $(".menu_line_bottom").toggleClass("string2");
        slideup.find("li").toggleClass("lis");
        slideup.find("ul").toggleClass("ulss");
        if (s_height == 0) {
            $(".video_container video").css("display","none");
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden'; //手机版设置这个。
            setTimeout(function(){
                document.body.style.height = 0;
            },700)
        } else {
            document.documentElement.style.overflow = '';
            document.body.style.overflow = ''; //手机版设置这个。
            document.body.style.height = '';
            setTimeout(function(){
                $(".video_container video").css("display","block");
            },700)
        }
    });
    //加入我们效果
    var $li = $("#join_us").find("li");
    $li.on("click",".title",function(){
        $(this).parent().find(".list").toggleClass("up_down");
        //$(this).parent().parent().find(".list").slideToggle();
        $(this).find(".icon").toggleClass("addtran");
    })
});